// import gsap, { Power2} from 'gsap';

export default class Accordion {
  
  constructor() {

    const containers: NodeListOf<HTMLElement> = document.querySelectorAll( '.c-accordion:not(.s-initiated)' );
    for ( let i: number = 0; i < containers.length; i++ ) this.setup( containers[ i ] );

  }


  private setup( container: HTMLElement ) {

    container.classList.add( 's-initiated' );
    
    const items: NodeListOf<HTMLElement> = container.querySelectorAll( '.c-accordion__item' );
    for ( let i: number = 0; i < items.length; i++ ) this.setupItem( items[ i ] );

  }


  private setupItem( item: HTMLElement ) {

    const toggle: HTMLElement|null = item.querySelector( '.c-accordion__toggle' );
    const content_container: HTMLElement|null = item.querySelector( '.c-accordion__content-container' );
    const content: HTMLElement|null = item.querySelector( '.c-accordion__content' );
    
    if ( toggle && content_container && content ) {

      toggle.addEventListener( 'click', ( event: MouseEvent ) => this.handleToggleClicked( event, item, content_container, content ) );

    }

  }


  private handleToggleClicked( event: MouseEvent, item: HTMLElement, content_container: HTMLElement, content: HTMLElement ) {

    event.preventDefault();

    item.classList.toggle( 's-toggled' );
    const toggled: boolean = item.classList.contains( 's-toggled' );
    if ( toggled ) {

      const content_height: number = content.offsetHeight;
      content_container.style.height = `${content_height}px`;
      // gsap.to( content_container, { duration: .4, height: `${content_height}px`, ease: Power2.easeInOut } );
      
    } else {

      content_container.style.height = `${0}`;
      // gsap.to( content_container, { duration: .4, height: 0, ease: Power2.easeInOut } );

    }

    return false;

  }

}