// import '../../css/modules/wysiwyg.css';

export default class TagCloud {
  
  constructor() {

    const containers: NodeListOf<HTMLElement> = document.querySelectorAll( '.c-tag-cloud' );
    for ( let i: number = 0; i < containers.length; i++ ) {
      this.setupContainer( containers[ i ] );
    }

  }


  private setupContainer( container: HTMLElement ) {
    
    const tags: NodeListOf<HTMLElement> = container.querySelectorAll( '.c-tag' );
    for ( let i: number = 0; i < tags.length; i++ ) {

      tags[ i ].addEventListener( 'mouseenter', () => this.handleEnterEvent( container, tags[ i ] ) );
      tags[ i ].addEventListener( 'touchstart', () => this.handleEnterEvent( container, tags[ i ] ) );
      tags[ i ].addEventListener( 'mouseleave', () => this.handleExitEvent( container, tags[ i ] ) );
      tags[ i ].addEventListener( 'touchend', () => this.handleExitEvent( container, tags[ i ] ) );

    }

  }


  private handleEnterEvent( container: HTMLElement, tag: HTMLElement ) {

    container.classList.add( 's-tag-hover' );
    tag.classList.add( 's-tag-hover' );

  }


  private handleExitEvent( container: HTMLElement, tag: HTMLElement ) {

    container.classList.remove( 's-tag-hover' );
    tag.classList.remove( 's-tag-hover' );

  }

}