// import '../../css/pages/projects_overview.css';

export default class ProjectsOverview {

  
  private _small_teasers_proceding_medium_teasers: NodeListOf<HTMLElement>;


  constructor() {

    window.addEventListener( 'resize', () => this.setTeaserHeight() );
    this.setTeaserHeight()

  }


  private setTeaserHeight() {

    if ( window.innerWidth < 1024 && this._small_teasers_proceding_medium_teasers ) {

      // Reset height on smaller screens if already applied
      for ( let i: number = 0; i < this._small_teasers_proceding_medium_teasers.length; i++ ) {

        this._small_teasers_proceding_medium_teasers[ i ].style.height = '';

      }

    } else {

      // Get teasers in case they havent been stored yet
      if ( !this._small_teasers_proceding_medium_teasers ) {

        this._small_teasers_proceding_medium_teasers = document.querySelectorAll( `
          .c-project-teaser--medium .c-project-teaser__content,
          .c-project-teaser--medium + .c-project-teaser--small .c-project-teaser__content,
          .c-project-teaser--medium + .c-project-teaser--small + .c-project-teaser--small .c-project-teaser__content
        ` );
      }

      if ( this._small_teasers_proceding_medium_teasers ) {

        // Get largest height of content
        let height: number = 0;
        for ( let i: number = 0; i < this._small_teasers_proceding_medium_teasers.length; i++ ) {

          this._small_teasers_proceding_medium_teasers[ i ].style.height = '';
          if ( this._small_teasers_proceding_medium_teasers[ i ].offsetHeight > height ) {

            height = this._small_teasers_proceding_medium_teasers[ i ].offsetHeight; 

          }

        }
         
        // Set content height to vertical align with the largest height
        for ( let i: number = 0; i < this._small_teasers_proceding_medium_teasers.length; i++ ) {

          this._small_teasers_proceding_medium_teasers[ i ].style.height = `${ height }px`;

        }
        
      }

    }

  }

}