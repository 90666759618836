// import '../../css/teasers/person.css';

export default class PersonTeaserTemplate {
  
  
  private _data: any;
  
  
  constructor( data: any ) {

    this._data = data;

  }


  public getTemplate() {

    let thumbnail = '';

    if ( this._data.thumbnail ) {

      thumbnail = `
        <picture>
          <source srcset="${this._data.thumbnail.srcset_webp}" type="image/webp">
          <source srcset="${this._data.thumbnail.srcset_jpg}" type="image/jpeg">
          <img
            sizes="${this._data.thumbnail.sizes}"
            src="${this._data.thumbnail.src}"
            alt="${this._data.thumbnail.alt}"
            class="c-media__image c-media__image--${this._data.thumbnail.direction} c-lazyload--image">
        </picture>
      `;
    }

    let position = '';
    if ( this._data.position && this._data.position.length ) {
      position = `
        <p class="c-person-teaser__position">${this._data.position}</p>
      `;
    }

    let phone = '';
    if ( this._data.phone && this._data.phone.length ) {
      phone = `
        <p>${this._data.phone}</p>
      `;
    }

    let email = '';
    if ( this._data.email && this._data.email.length ) {
      email = `
        <p><a href="mailto:${this._data.email}" class="c-person-teaser__email">${this._data.email}</a></p>
      `;
    }

    let offices = '';
    if ( this._data.offices && this._data.offices.length ) {
      offices = `
        <div class="c-person-teaser__office">
          <p class="c-tag c-tag--small">${this._data.offices[ 0 ].name}</p>
        </div>
      `;
    }

    return `
      <div class="c-person-teaser c-person-teaser--small">

        <div class="c-media">${thumbnail}</div>

        <div class="c-person-teaser__content">
          <div class="c-person-teaser__content__top">
            <p class="c-person-teaser__name">${this._data.title}</p>
            ${position}
          </div>

          <div class="c-person-teaser__content__bottom">
            ${phone}
            ${email}
          </div>

          ${offices}
        </div>

      </div>
    `;
  }

}