export default class Term {
  
  
  private _data: any;
  
  
  constructor( data: any ) {

    this._data = data;

  }


  public getTemplate() {

    let parent_url: string = this._data.main_url;
    let parent_term_markup = '';
    if ( this._data.parent_term) {
      
      parent_url = this._data.parent_term.href;

      parent_term_markup = `
        <div class="c-terms-menu__selected-term">
          <p class="c-terms-menu__selected-term__label">${this._data.parent_term.innerText}</p>
        </div>
      `;
    }
    
    return `
      <div class="c-terms-menu__selected-terms">

        ${parent_term_markup}
            
        <div class="c-terms-menu__selected-term">
          <p class="c-terms-menu__selected-term__label">${this._data.term_title}</p>
          <a href="${parent_url}" class="c-terms-menu__selected-term__close"></a>
        </div>

      </div>
    
    `;
  }

}