import SwiperCore from 'swiper/core';
// import '../../css/pages/project.css';

export default class Project {
  

  private _presentation: HTMLElement;
  private _presentation_initiated: boolean = false;
  private _presentation_videos: NodeListOf<HTMLVideoElement>;
  private _project_videos: NodeListOf<HTMLVideoElement>;

  constructor() {


    this._presentation = document.querySelector( '.c-presentation' );
    if ( this._presentation ) {
      
      document.body.classList.add( 's-has-presentation' );

      const presentation_activate_buttons: NodeListOf<HTMLElement> = document.querySelectorAll( '.c-project__presentation__toggle' );
      for ( let i: number = 0; i < presentation_activate_buttons.length; i++ ) {
        
        presentation_activate_buttons[ i ].addEventListener( 'click', ( event: Event ) => this.activatePresentation( event ) );
        
      }

      // this.activatePresentation( null );

      const presentation_close_button: HTMLElement = document.querySelector( '.c-presentation__close' );
      if ( presentation_close_button ) {

        presentation_close_button.addEventListener( 'click', ( event: Event ) => this.deactivatePresentation( event ) );

      }
      
    }


  }


  private setupSlideshow( container ) {

    const slideshow_element: HTMLElement = container.querySelector( '.c-presentation__slideshow-container' );
    if ( slideshow_element ) {
    
      const swiper = new SwiperCore( slideshow_element, {

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        slidesPerView: 1,
        spaceBetween: 0,

      } );

      swiper.on('slideChange', function ( slideshow: SwiperCore) {
      
        const last_slide: HTMLElement = <HTMLElement>slideshow.slides[ slideshow.previousIndex ];
        const video: HTMLVideoElement = last_slide.querySelector( 'video' );
        if ( video ) video.pause();
  
      } );

    }

  }


  private activatePresentation( event?: Event) {

    if ( event ) event.preventDefault();

    if ( !this._presentation_initiated ) {
      
      this.setupSlideshow( this._presentation );

      this._presentation_videos = this._presentation.querySelectorAll( 'video' );
      this._project_videos = document.querySelectorAll( '.c-project video' );
      
      this._presentation_initiated = true;

    }
    
    this.pauseVideos( this._project_videos );

    document.body.classList.add( 's-presentation-visible' );

    return false;

  }


  private deactivatePresentation( event: Event) {

    event.preventDefault();

    document.body.classList.remove( 's-presentation-visible' );
    this.pauseVideos( this._presentation_videos );

    return false;
    
  }


  private pauseVideos( videos: NodeListOf<HTMLVideoElement> ) {

    if ( videos ) {
      
      for ( let i: number = 0; i < videos.length; i++ ) {
      
        videos[ i ].pause();
      
      }
    
    }

  }


}