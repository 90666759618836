import Term from "../templates/Term";


export default class TermsMenu {

  
  private _current_terms_data;
  
  private contentUpdated: Event = new Event( 'contentUpdated' );


  constructor() {

    const containers: NodeListOf<HTMLElement> = document.querySelectorAll( '.c-terms-menu' );
    for ( let i: number = 0; i < containers.length; i++ ) this.setup( containers[ i ] );

  }


  private setup( container: HTMLElement ) {

    const toggle: HTMLElement|null = container.querySelector( '.c-terms-menu__toggle:not(.s-initiated)' );
    if ( toggle ) {

      toggle.classList.add( 's-initiated' );

      toggle.addEventListener( 'click', ( event: MouseEvent ) => {

        event.preventDefault();
        container.classList.toggle( 's-activated' )
        return false;

      } );

    }

    const links: NodeListOf<HTMLAnchorElement> = container.querySelectorAll( '.c-terms-menu__item__label' );
    for ( let i: number = 0; i < links.length; i++ ) {

      links[ i ].addEventListener( 'click', ( event: MouseEvent ) => {
        
        const target: HTMLAnchorElement = event.target as HTMLAnchorElement;
        this.handleTermClicked( target, container, links );

      } );

    }


    this.setupTermCloseLinks( container );

  }


  private setupTermCloseLinks( container: HTMLElement ) {

    const close_links: NodeListOf<HTMLAnchorElement> = container.querySelectorAll( '.c-terms-menu__selected-term__close' );
    for ( let i: number = 0; i < close_links.length; i++ ) {
      
      close_links[ i ].addEventListener( 'click', ( event: Event ) => this.handleCloseTermClicked( event, container, close_links[ i ] ));
      
    }

  }


  private handleTermClicked( target: HTMLAnchorElement, container: HTMLElement, links?: NodeListOf<HTMLAnchorElement> ) {
    
    if ( !links ) links = container.querySelectorAll( '.c-terms-menu__item__label' );
    for ( let i: number = 0; i < links.length; i++ ) {
      
      links[ i ].classList.remove('s-active');

    }

    const selected_terms_container: HTMLElement = container.querySelector( '.c-terms-menu__selected-terms' );

    if ( target ) {

      // If there is a target we can add update the activated terms
      target.classList.add( 's-active' );
      const parent: HTMLElement = target.parentElement.parentElement.parentElement as HTMLElement;
      
      let parent_term: HTMLAnchorElement;
      if ( parent.classList.contains( 'c-terms-menu__item' ) ) {
        
        parent_term = parent.querySelector( '.c-terms-menu__item__label' );
        if ( parent_term ) parent_term.classList.add( 's-active' );

      }

      this._current_terms_data = {

        "main_url": container.dataset.mainoverviewpagelink,
        "parent_term": parent_term ? parent_term : null,
        "term_title": target.innerText,

      };

      const term: Term = new Term( this._current_terms_data );
      const template: any = term.getTemplate();

      if ( template ) {

        if ( selected_terms_container ) {

          selected_terms_container.innerHTML = template;

          this.setupTermCloseLinks( container );
          window.dispatchEvent( this.contentUpdated );

        }

      }

      container.classList.remove( 's-activated' );

    } else if ( selected_terms_container ) {

      // If no target is found, we empty the selected terms menu
      selected_terms_container.innerHTML = '';

    }

  }


  private handleCloseTermClicked( event: Event, container: HTMLElement, link: HTMLAnchorElement ) {

    event.preventDefault();

    // Get relative URL from link so we can match against the links in the dropdown menu
    const relative_url: string = link.href.replace(/(.+\w\/)(.+)/,"/$2");
    // Find term link from dropdown where url ends with relative path.
    const target: HTMLAnchorElement = container.querySelector( `.c-terms-menu__item__label[href$="${relative_url}"]`)
    
    this.handleTermClicked( target, container );

    return false;

  }

}