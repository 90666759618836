import SwiperCore, { Navigation, Pagination } from 'swiper/core';

import 'swiper/swiper-bundle.css';
// import '../../css/modules/slideshow.css';

SwiperCore.use([Navigation, Pagination]);

export default class Slideshow {
  
  constructor() {

    const containers: NodeListOf<HTMLElement> = document.querySelectorAll( '.c-slideshow__slideshow' );
    for ( let i: number = 0; i < containers.length; i++ ) {

      this.setupSlideshow( containers[ i ] );

    }

  }


  private setupSlideshow( container: HTMLElement ) {

    const swiper = new SwiperCore( container, {

      pagination: {
        clickable: true,
        el: '.swiper-pagination',
        type: 'bullets',
      },
      slidesPerView: 'auto',
      spaceBetween: 18
    } );

    swiper.on('slideChange', function ( slideshow: SwiperCore) {
      
      const last_slide: HTMLElement = <HTMLElement>slideshow.slides[ slideshow.previousIndex ];
      const video: HTMLVideoElement = last_slide.querySelector( 'video' );
      if ( video ) video.pause();

    } );

  }

}