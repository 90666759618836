// import '../../css/teasers/person.css';

export default class PositionTeaserTemplate {
  
  
  private _data: any;
  
  
  constructor( data: any ) {

    this._data = data;

  }


  public getTemplate() {

    let department = '';
    if ( this._data.department && this._data.department.length ) {
      department = `
      <div class="c-link-list__link__label-container">
        <p class="c-tag c-tag--small c-tag--opacity">${this._data.department}</p>
      </div>
      `;
    }

    return `
      <a href="${this._data.job_posting_link}" target="_blank" class="c-link-list__link l-columns-container">
        ${department}
        <p class="c-link-list__link__title">${this._data.title}</p>
        <p class="c-link-list__link__read-more">Læs mere</p>
      </a>
    `;
  }

}