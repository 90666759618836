export default class MiscSearchResults {
  
  
  private _data: any;
  
  
  constructor( data: any ) {

    this._data = data;

  }


  public getTemplate() {

    let excerpt = '';
    if ( this._data.excerpt && this._data.excerpt.length ) {
      excerpt = this._data.excerpt;
    }

    return `
      <div class="c-search-results__result c-search-results__result--misc f-body">
        <a href="${this._data.permalink}" class="c-search-results__result__headline">${this._data.title}</a>
        <p class="c-search-results__result__excerpt">${excerpt}</p>
        <a href="${this._data.permalink}" class="c-search-results__result__link">${this._data.permalink}</a>
      </div>
    `;
  }

}