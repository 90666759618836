export default class Header {

    private _body_element: HTMLElement;
    private _menu_open: boolean = false;
    
    private _scroll_position: number = 0;
    private _scroll_down_start: number = 0;
    private _scroll_disabled: boolean = false;
    private _scroll_top: number = 0;

    private closeSearchEvent:Event = new Event( 'closeSearch' );

    constructor() {

      this.init();

    }


    private init() {

        this._body_element = document.body;

        const hamburger_element: HTMLElement = document.querySelector( '.c-hamburger__link' );
        if ( hamburger_element ) {

            hamburger_element.addEventListener( 'click', ( event: Event ) => this.hamburgerClicked( event ) );
            // hamburger_element.click();

        }

        window.addEventListener( 'scroll', ( event: Event) => this.handleScroll() );
        window.addEventListener( 'closeMenu', () => this.closeMenu() );

    }


    private hamburgerClicked( event: Event ) {

        event.preventDefault();

        this._menu_open = !this._menu_open;

        if ( this._menu_open) {

            this.disableScroll();
            document.body.classList.add( 's-menu-open' );

            // Make sure to close search if it's open
            window.dispatchEvent( this.closeSearchEvent );
            
        } else {
            
            this.closeMenu();

        }


        return false;
        
    }


    private closeMenu() {

        this._menu_open = false;

        document.body.classList.remove( 's-menu-open' );
        
        this.enableScroll();

    }

    private handleScroll() {
        
        const scroll: number = window.pageYOffset;
        if ( scroll <= 0 ) {

            this._body_element.classList.remove( 's-header-hidden' );

        } else if ( this._scroll_position <= scroll  ) {
    
            // if we continue scrolling up, the difference between 
            // this._scroll_down_start and scroll will eventually reach a 100px
            if ( scroll - this._scroll_down_start > 0) {

                // @ts-ignore
                window.SCROLL_DIRECTION = 'down';

                this._body_element.classList.add( 's-header-hidden' );

            }
    
        } else {

            // we are scrolling up

            // @ts-ignore
            window.SCROLL_DIRECTION = 'up';

            this._scroll_down_start = window.pageYOffset;
            this._body_element.classList.remove( 's-header-hidden' );

        }
        
        this._scroll_position = window.pageYOffset;

    }


    private disableScroll() {
    
        if ( this._scroll_disabled ) return;
        
        this._scroll_top = window.pageYOffset || document.documentElement.scrollTop;
        document.body.style.top = `${-1 * this._scroll_top}px`;

        this._scroll_disabled = true;

    }


    private enableScroll() {

        if ( !this._scroll_disabled ) return;
    
        window.scrollTo( 0, this._scroll_top );
        document.body.style.top = '';
    
        this._scroll_disabled = false;

    }

}