import SwiperCore, { Navigation, Pagination } from 'swiper/core';

import 'swiper/swiper-bundle.css';
// import '../../css/modules/slideshow.css';

SwiperCore.use([Navigation, Pagination]);

export default class Timeline {
  
  constructor() {

    const containers: NodeListOf<HTMLElement> = document.querySelectorAll( '.c-timeline__slideshow' );
    for ( let i: number = 0; i < containers.length; i++ ) {

      this.setupSlideshow( containers[ i ] );

    }

  }


  private setupSlideshow( container: HTMLElement ) {

    const swiper = new SwiperCore( container, {

      pagination: {
        clickable: true,
        el: '.swiper-pagination',
        type: 'bullets',
      },
      slidesPerView: 'auto',
      spaceBetween: 18,

    } );

  }

}