// import { TweenMax, Power2, Power4, Circ } from 'gsap';
import gsap, { Power2 } from 'gsap';
// @ts-ignore
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";

export class PageTransition {

    public transitionType: string = '';
    
    private _body:HTMLBodyElement = <HTMLBodyElement>document.body;
    
    private _transition_container: HTMLElement = <HTMLElement>document.querySelector( '.c-main' );
    private _content_container: HTMLElement = <HTMLElement>document.querySelector( '.c-main' );
    private _overview_container: HTMLElement = <HTMLElement>document.querySelector( '.c-overview__content' );
    private _content_hidden: boolean = false;

    private _transition_duration: number = 200;
    private _min_transition_duration: number = 800;
    private _transition_started_time: number;

    private closeMenu: Event = new Event( 'closeMenu' );
    private newContentInserted: Event = new Event( 'newContentInserted' );
    private contentUpdated: Event = new Event( 'contentUpdated' );
    private contentFullyRevealed: Event = new Event( 'contentFullyRevealed' );


    constructor() {

        // @ts-ignore
        gsap.registerPlugin( ScrollToPlugin );

    }


    public hideContent( event?: CustomEvent ) {

        window.dispatchEvent( this.closeMenu );

        this._content_hidden = false;
        this.transitionType = '';
        if ( event ) {

            const target: HTMLElement = event.detail.target;

            if (
                target.classList.contains( 'c-terms-menu__item__label' ) ||
                target.classList.contains( 'c-terms-menu__selected-term__close' ) ) {
                
                this.transitionType = 'term';
                this._overview_container = document.querySelector( '.c-overview__content' );

            } else if ( target.classList.contains( 'c-main-nav__link--main-menu' ) ) {

                this.transitionType = 'mainMenu';
                this._body.classList.add( 's-instant-hide' );

            }
        }

        const transition_container: HTMLElement = this.transitionType === 'term' ? this._overview_container : this._transition_container;
        const duration: number = this.transitionType === 'mainMenu' ? 0 : this._transition_duration / 1000;
        gsap.to( transition_container, { duration: duration, opacity: 0, ease: "power2.inOut", onBegin: () => {

            this._transition_started_time = Date.now();

        }, onComplete: () => {

            const timeout: number = this._min_transition_duration - this._transition_duration - duration * 1000; 
            setTimeout(() => {

                this._content_hidden = true;

            }, timeout );
            
        } } );

        this._content_container.style.pointerEvents = 'none';

        this.unloadVideos();

    }


    private loadNewAssets( selector: string, new_markup: string, existing_container: HTMLElement, trigger_load: boolean = false ) {

        const temp_constainer: HTMLElement = document.createElement( 'div' );
        temp_constainer.innerHTML = new_markup;
        const asset_elements: NodeListOf<HTMLLinkElement> = temp_constainer.querySelectorAll( selector );
        if ( existing_container && asset_elements && asset_elements.length ) {

            const asset_elements_loaded: NodeListOf<HTMLLinkElement> = document.querySelectorAll( selector );
            let new_assets_markup: string = "";
            for ( let i: number = 0; i < asset_elements.length; i++ ) {

                let loaded: boolean = false;
                for ( let j: number = 0; j < asset_elements_loaded.length; j++ ) {
                    if ( asset_elements[ i ].id === asset_elements_loaded[ j ].id ) loaded = true;
                }
                if ( !loaded ) new_assets_markup += asset_elements[ i ].outerHTML;
            }

            if ( new_assets_markup && new_assets_markup.length ) existing_container.insertAdjacentHTML( 'beforeend', new_assets_markup );

            if ( trigger_load ) {
                // document.dispatchEvent("load");
            }

        }
    }


    public injectContent( scroll_pos = { x: 0, y: 0 } ) {

        if ( this._content_hidden ) {

            window.scrollTo( scroll_pos.x, scroll_pos.y );

            let body_classes: string = history.state.bodyClasses;
            body_classes = this.transitionType === 'mainMenu' ? `${body_classes} s-instant-hide` : body_classes;
            
            if ( this._body && ( body_classes || body_classes === '' ) ) {
                
                body_classes = body_classes.replace('s-initial-reveal', '');
                body_classes = `${body_classes} s-show-content`;
                const is_touch: boolean = this._body.classList.contains( 'touch-device' );
                body_classes = ( is_touch ) ? `${body_classes} touch-device` : body_classes;
                
                this._body.setAttribute( 'class', body_classes );
                
            }

            const new_content = this.transitionType === 'term' ? history.state.markup[ 1 ] : history.state.markup[ 0 ];
            if ( this.transitionType === 'term' && this._overview_container && new_content ) {
                
                this._overview_container.innerHTML = new_content;

            } else if ( this._content_container && new_content ) {
        
                this._content_container.innerHTML = new_content;
                this._overview_container = <HTMLElement>document.querySelector( '.c-overview__content' );
    
            }

            // Compare timestamps to make sure minimum time has passed before content reveal
            // to make sure the transition is not too abrupt
            const current_time: number = Date.now();
            const time_difference: number = current_time - this._transition_started_time;
            const timeout: number = this._min_transition_duration > time_difference ? this._min_transition_duration : time_difference;

            setTimeout(() => {

                // console.log('injected', timeout);
                // @ts-ignore
                window.SCROLL_DIRECTION = 'down';
                this._body.classList.remove( 's-instant-hide' );

                window.dispatchEvent( this.newContentInserted );
                window.dispatchEvent( this.contentUpdated );
                
            }, timeout);

        } else {
            
            setTimeout( () => {
            
                this.injectContent( scroll_pos );
            
            }, 200 );
        
        }

    }




    public showContent() {

        
        const transition_container: HTMLElement = this.transitionType === 'term' ? this._overview_container : this._transition_container;

        this.setBackgroundColor();

        gsap.to( transition_container, { duration: this._transition_duration / 1000, opacity: 1, ease: "power2.inOut", onComplete: () => {
            
            window.dispatchEvent( this.contentFullyRevealed );
            
            // Unset inline styles
            transition_container.style.opacity = '';
            transition_container.style.pointerEvents = '';

        } } );

        this._content_container.style.pointerEvents = 'auto';
        this._content_hidden = false;
    
    }


    public setBackgroundColor() {

        let background_color_hex: string = '#ffffff';

        if ( this._body.classList.contains( 's-sand' ) ) {

            background_color_hex = '#FAF5E0';

        } else if ( this._body.classList.contains( 's-green' ) ) {

            background_color_hex = '#EAEEE9';

        }

        document.documentElement.style.setProperty( '--bg-color', background_color_hex );

    }


    private unloadVideos() {

        const videos: NodeListOf<HTMLVideoElement> = document.querySelectorAll( 'video' );
        for ( let i: number = 0; i < videos.length; i++ ) {

            const source_elements: NodeListOf<HTMLSourceElement> = videos[ i ].querySelectorAll( 'source' );
            for ( let j: number = 0; j < source_elements.length; j++ ) {

                source_elements[ j ].removeAttribute('src');

            }

            videos[ i ].pause();
            videos[ i ].preload = 'none';
            videos[ i ].setAttribute( 'preload', 'none' );

        }

    }

}