// import gsap, { Power2} from 'gsap';


interface CardItem {

  element: HTMLElement,
  defaultHeight: number,
  headline: HTMLElement,
  headlineHeight: number,
  i: number,
  textContainer: HTMLElement,
  text: HTMLElement,
  textHeight: number,
  toggled: boolean

}

export default class Cards {
  
  
  private _items: Array<CardItem> = [];
  private _above_toggled: Array<boolean> = [ false, false, false, false ];
  

  constructor() {

    const containers: NodeListOf<HTMLElement> = document.querySelectorAll( '.c-cards' );
    for ( let i: number = 0; i < containers.length; i++ ) this.setup( containers[ i ] );

  }


  private setup( container: HTMLElement ) {

    const items: NodeListOf<HTMLElement> = container.querySelectorAll( '.c-cards__item' );
 
    for ( let i: number = 0; i < items.length; i++ ) {

      const headline_element: HTMLElement = items[i].querySelector( '.c-cards__item__subheading' );
      const text_container_element: HTMLElement = items[i].querySelector( '.c-cards__item__text-container' );
      const text_element: HTMLElement = items[i].querySelector( '.c-cards__item__text-container p' );
      
      const item: CardItem = {

        element: items[ i ],
        defaultHeight: 0,
        headline: headline_element,
        headlineHeight: 0,
        i: i,
        textContainer: text_container_element,
        text: text_element,
        textHeight: 0,
        toggled: false

      }

      this._items.push( item );

      items[i].addEventListener( 'click', () => this.toggleItem( item ) );

    }

    this.handleResize();

    window.addEventListener( 'resize', () => this.handleResize() );

  }


  private handleResize() {

    for ( let i: number = 0; i < this._items.length; i++ ) {

      if ( !this._items[ i ].toggled ) {
 
        this._items[ i ].defaultHeight = this._items[ i ].element.offsetHeight;

      }

      this._items[ i ].headlineHeight = this._items[ i ].headline.offsetHeight;
      this._items[ i ].textHeight = this._items[ i ].text.offsetHeight;

      this.setHeight( this._items[ i ] );

    }

    this.verticalAlignCards();

  }


  private setHeight( item: CardItem ) {

    if ( item.toggled ) {

      item.element.style.height = `${item.defaultHeight + item.textHeight + 31}px`;
      item.textContainer.style.height = `${item.textHeight}px`;
      
    } else {
      
      item.element.style.height = `${item.defaultHeight}px`;
      item.textContainer.style.height = null;

    }

  }


  private verticalAlignCards() {

    // Loop over cars in provious row to see if any cards are toggled
    let all_above_closed = true;
    
    // Handle margin-top for all cards
    let tallest: number = -1;
    let tallest_height: number = -1;
    let tallest_default_height: number = -1;
    
    const cards_per_row = window.innerWidth > 1024 ? 4 : 3;
    for ( let i: number = 0; i < this._items.length; i++ ) {

      // Reset numbers when iterating over new row
      if ( i % cards_per_row === 0 && i > 0 ) {

        tallest = -1;
        tallest_height = -1;
        tallest_default_height = -1;

        all_above_closed = true;
        for ( let j: number = 0; j < cards_per_row; j++ ) {

          const above_i: number = i - cards_per_row + j;
          if ( this._items[ above_i ].toggled ) all_above_closed = false;

          // Get tallest item in last row
          const current_height: number = parseFloat(this._items[ above_i ].element.style.height );
          if ( current_height > tallest_height ) {

            tallest = above_i;
            tallest_height = current_height;
            
          }

          // tallest_default_height = tallest_default_height > this._items[ above_i ].defaultHeight ? tallest_default_height : this._items[ above_i ].defaultHeight; 
          // if ( !this._items[ above_i].toggled ) this._items[ above_i].element.style.height = `${tallest_default_height}px`;
          
        }

      }

      
      if ( window.innerWidth > 764) {

        if ( i < cards_per_row ) {

          // Reset margin on top row
          this._items[ i ].element.style.marginTop = `0px`;

        } else {

          // Set margin to items after first row
          const above: CardItem = this._items[ i - cards_per_row ];

          if ( above.toggled ) {
            
            this._items[ i ].element.style.marginTop = `0px`;
          
          } else {

            const above_height = parseFloat( above.element.style.height ); 
            const above_margin_top = parseFloat( above.element.style.marginTop );
            const margin_top = tallest_height - above_height - above_margin_top;

            this._items[ i ].element.style.marginTop = `${-margin_top}px`;

          }


        }

      }

    }

  }


  private toggleItem( item: CardItem ) {

    for ( let i: number = 0; i < this._items.length; i++ ) {
      
      if ( this._items[ i ].toggled && i !== item.i ) {

        this._items[ i ].toggled = false;
        this._items[ i ].element.classList.remove( 's-toggled' );
        this.setHeight( this._items[ i ] );

      }
      
    }

    item.toggled = !item.toggled
    item.element.classList.toggle( 's-toggled' );

    this.setHeight( item );

    this.verticalAlignCards();

  }

}