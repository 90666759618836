import PositionTeaserTemplate from "../templates/PositionTeaserTemplate";
import PersonTeaserTemplate from "../templates/PersonTeaserTemplate";
import ProjectTeaserTemplate from "../templates/ProjectTeaserTemplate";
import PostTeaserTemplate from "../templates/PostTeaserTemplate";
import MiscSearchResults from "../templates/MiscSearchResult";

export default class Search {


  private _activly_toggled: boolean = false;
  private _scroll_pos: number = 0;
  private _toggled: boolean = false;
  private _query_url: string = window.location.origin + '/wp-json/erik/v1/search?q=';
  private _request: XMLHttpRequest;

  private _main_element: HTMLElement;
  private _input_element: HTMLInputElement;
  private _persons_container: HTMLElement;
  private _persons_results_container: HTMLElement;
  private _projects_container: HTMLElement;
  private _projects_results_container: HTMLElement;
  private _news_container: HTMLElement;
  private _news_results_container: HTMLElement;
  private _positions_container: HTMLElement;
  private _positions_results_container: HTMLElement;
  private _misc_container: HTMLElement;
  private _misc_results_container: HTMLElement;

  private _active_search: boolean = false;
  private _suggestion_timeout: any;
  private _suggestion: string;
  private _suggestions: Array<string>;
  private _suggestion_interval: any;
  private _suggestion_last: number = -1;

  private enableLazyLoad: Event = new Event( 'enableLazyLoad' );


  constructor() {

    this._main_element = document.querySelector( '.c-main' );
    this._persons_container = document.querySelector( '.c-search-results__persons' );
    this._projects_container = document.querySelector( '.c-search-results__projects' );
    this._news_container = document.querySelector( '.c-search-results__news' );
    this._positions_container = document.querySelector( '.c-search-results__positions' );
    this._misc_container = document.querySelector( '.c-search-results__misc' );
    
    const container: HTMLElement = document.querySelector( '.c-search-form__container' );
    if (
      container &&
      this._persons_container &&
      this._projects_container &&
      this._news_container &&
      this._misc_container
    ) {

      this._input_element = container.querySelector( '.c-search-form__input' );
      this._persons_results_container = this._persons_container.querySelector( '.c-search-results__items' );
      this._projects_results_container = this._projects_container.querySelector( '.c-search-results__items' );
      this._news_results_container = this._news_container.querySelector( '.c-search-results__items' );
      this._positions_results_container = this._positions_container.querySelector( '.c-search-results__items' );
      this._misc_results_container = this._misc_container.querySelector( '.c-search-results__items' );

      if ( this._input_element ) {

        // Ignore normal form submit
        const form_element: HTMLFormElement = container.querySelector( '.c-search-form' );
        if ( form_element ) {

          form_element.addEventListener('submit', ( event: Event ) => {
            
            event.preventDefault();
            return false;

          } );

        }

        // Listen for keyboard events to activate search
        window.addEventListener( 'keyup', ( event: KeyboardEvent ) => this.handleWindowKeyUp( event) );
        this._input_element.addEventListener( 'keyup', () => this.search() );

        // Manual search toggle elements
        const activate_element: HTMLElement = document.querySelector( '.c-header__search-link' );
        if ( activate_element ) activate_element.addEventListener( 'click', ( event: Event ) => this.handleActivateClick( event ) );
        
        // activate_element.click();
        // this.search();

        const deactivate_element: HTMLElement = document.querySelector( '.c-search__close' );
        if ( deactivate_element ) deactivate_element.addEventListener( 'click', ( event: Event ) => this.handleDeactivateClick( event ) );

        // Listen for events to close search
        window.addEventListener( 'closeSearch', () => this.toggleSearch( false ) );

        // Setup suggestions
        this.setupSuggestions();

      }

    }

  }


  private setupSuggestions() {

    const suggestions_element: HTMLElement = document.querySelector( '.c-search-form__suggestion' );
    if ( suggestions_element ) {

      // Get suggestions from data property
      this._suggestions = suggestions_element.dataset.suggestions.split(" , ");
      if ( this._suggestions && this._suggestions.length ) {

        // Open suggestions when user isn't active
        this.suggestionTimeout();
        window.addEventListener( 'mousemove', () => this.suggestionTimeout() );
        window.addEventListener( 'scroll', () => this.suggestionTimeout() );
        window.addEventListener( 'click', () => this.suggestionTimeout() );
        window.addEventListener( 'touchstart', () => this.suggestionTimeout() );
        window.addEventListener( 'touchmove', () => this.suggestionTimeout() );

        // Search when suggestion clicked
        this._input_element.addEventListener( 'click', () => {

          if ( !this._active_search ) {
            this.clearSuggestions();

            // Only search if there is a value in input field
            if ( this._suggestion && this._suggestion.length ) {

              this._input_element.value = this._suggestion;
              this.search();

            }
          }

        } );

        // Change suggestion
        if ( !this._activly_toggled && !this._active_search ) this.setSuggestion();

      }

    }

  }


  private clearSuggestions() {

    document.body.classList.remove( 's-search-suggestions' );
    
    this._input_element.placeholder = this._input_element.dataset.placeholder;
    this._input_element.focus();

    if ( this._suggestion_timeout ) clearTimeout( this._suggestion_timeout );
    if ( this._suggestion_interval ) clearInterval( this._suggestion_interval );

  }


  private getNewSuggestion() {
      
    // Get random suggestion
    const suggestion: number = Math.floor( Math.random() * this._suggestions.length );
    if ( this._suggestion_last === suggestion ) return this.getNewSuggestion();

    this._suggestion_last = suggestion;
    return suggestion;
  
  }

  private setSuggestion() {

    // Get random suggestion
    const random_suggestion: number = this.getNewSuggestion();
    this._suggestion = this._suggestions[ random_suggestion ];
    this._input_element.classList.add( 's-hide' );
    setTimeout(() => {
      
      this._input_element.placeholder = this._suggestion + "?";
      this._input_element.classList.remove( 's-hide' );

    }, 350);

  }


  private suggestionTimeout() {

    if ( this._suggestion_timeout ) clearTimeout( this._suggestion_timeout);
    this._suggestion_timeout = setTimeout( () => {

      if (
        !this._toggled &&
        !this._activly_toggled &&
        !document.body.classList.contains( 's-presentation-visible' )
      ) {

        this._toggled = true;

        this.positionMainContainer();

        document.body.classList.add( 's-search-open' );
        document.body.classList.add( 's-search-suggestions' );
        document.body.classList.remove( 's-menu-open' );

        this._input_element.value = "";
        this._input_element.placeholder = this._input_element.dataset.placeholder;
        this._input_element.focus();

        // Make sure to clear results in case a search has already been made
        this.clearResults();

        this.suggestionInterval();

      }

    }, 60000 );
    // }, 10000 );
    // }, 1000 );

  }


  private suggestionInterval() {

    this._suggestion_interval = setInterval( () => {
      if ( !this._activly_toggled ) this.setSuggestion()
    }, 3000 );

  }


  private handleActivateClick( event: Event ) {

    event.preventDefault();

    this._activly_toggled = true;
    this._input_element.placeholder = this._input_element.dataset.placeholder;
    this._input_element.classList.remove( 's-hide' );

    this.toggleSearch();

    return false;

  }


  private handleDeactivateClick( event: Event ) {

    event.preventDefault();

    this._active_search = false;
    this.toggleSearch();

    return false;

  }


  private positionMainContainer() {

    this._scroll_pos = window.scrollY;
    this._main_element.style.marginTop = `${-this._scroll_pos}px`;
    this._main_element.style.height = `${window.innerHeight + this._scroll_pos}px`;

    setTimeout(() => {
        
      document.body.classList.add( 's-search-open-animation-completed' );

    }, 400); // 400ms is the transition time for the search to open

  }


  private toggleSearch( toggled?: boolean ) {

    if ( toggled === undefined ) {
      this._toggled = !this._toggled;
    } else {
      this._toggled = toggled;
    }

    if ( this._toggled ) {

      this.positionMainContainer();

      document.body.classList.add( 's-search-open' );

      // Close menu if it's open
      document.body.classList.remove( 's-menu-open' );

      this._input_element.classList.remove( 's-hide' );
      this._input_element.focus();

    } else {

      this._activly_toggled = false;

      this.clearSuggestions();

      this._main_element.style.removeProperty( 'margin-top');
      this._main_element.style.removeProperty( 'height');
      document.body.classList.remove( 's-search-suggestions' );
      document.body.classList.remove( 's-search-open' );
      document.body.classList.remove( 's-search-open-animation-completed' );
      
      this._input_element.blur();

      window.scrollTo( 0, this._scroll_pos );

    }

  }


  private handleWindowKeyUp( event: KeyboardEvent ) {

    document.body.classList.remove( 's-search-suggestions' );
    this._input_element.classList.remove( 's-hide' );
    
    this.clearSuggestions();

    if (
      this._toggled && event.code === 'Escape'
      // || this._toggled && this._input_element.value === '' && event.code === 'Backspace'
    ) {

      this.toggleSearch();

    } else if (
      !this._toggled &&
      this.isValidSign(event.code)
    ) {

      this.toggleSearch();
      this._input_element.value = event.key;
      
    }

  }


  private search() {

    if ( this._request ) this._request.abort();

    const val: string = this._input_element.value;
    
    if ( val && val.length > 1 ) {

      this._request = new XMLHttpRequest();
      this._request.addEventListener( 'load', ( event: Event ) => this.fetchResultsSucceeded( event ) );
      this._request.addEventListener( 'error', ( event: Event ) => this.fetchResultsFailed( event ) );
      this._request.open( 'GET', this._query_url + val, true );
      this._request.send();

    } else {

      this.clearResults();  

    }

  }


  private clearResults() {

    this._persons_container.classList.remove( 's-active' );
    this._persons_results_container.innerHTML = '';
    this._projects_container.classList.remove( 's-active' );
    this._projects_results_container.innerHTML = '';
    this._news_container.classList.remove( 's-active' );
    this._news_results_container.innerHTML = '';
    this._positions_container.classList.remove( 's-active' );
    this._positions_results_container.innerHTML = '';
    this._misc_container.classList.remove( 's-active' );
    this._misc_results_container.innerHTML = '';

  }


  private fetchResultsFailed( event:Event ) {

    console.error('fetchResultsFailed');
    this._request.removeEventListener( 'load', this.fetchResultsSucceeded );
    this._request = null;

    return false;

  }


  private fetchResultsSucceeded(event:Event) {

    this._request.removeEventListener( 'load', this.fetchResultsSucceeded );
    this._request = null;
    this._active_search = true;
    const target: XMLHttpRequest = <XMLHttpRequest>event.target;
    
    if ( target.status >= 200 && target.status <= 404 ) {
      
      const response_json: JSON = JSON.parse(target.response);
      // console.log(response_json);
      // @ts-ignore
      const persons: Array<string> = response_json.persons.map( ( data ) => {
        const person_template = new PersonTeaserTemplate( data );
        return person_template.getTemplate();
      });

      if ( persons && persons.length ) {
        this._persons_container.classList.add( 's-active' );
        this._persons_results_container.innerHTML = persons.join('');
      } else {
        this._persons_container.classList.remove('s-active');
        this._persons_results_container.innerHTML = '';
      }

      // @ts-ignore
      const projects: Array<string> = response_json.projects.map( ( data ) => {
        const project_template = new ProjectTeaserTemplate( data );
        return project_template.getTemplate();
      });

      if ( projects && projects.length ) {
        this._projects_container.classList.add( 's-active' );
        this._projects_results_container.innerHTML = projects.join('');
      } else {
        this._projects_container.classList.remove( 's-active' );
        this._projects_results_container.innerHTML = '';
      }

      // @ts-ignore
      const news: Array<string> = response_json.news.map( ( data ) => {
        const news_template = new PostTeaserTemplate( data );
        return news_template.getTemplate();
      });

      if ( news && news.length ) {
        this._news_container.classList.add( 's-active' );
        this._news_results_container.innerHTML = news.join('');
      } else {
        this._news_container.classList.remove( 's-active' );
        this._news_results_container.innerHTML = '';
      }

      // @ts-ignore
      const positions: Array<string> = response_json.positions.map( ( data ) => {
      const positions_template = new PositionTeaserTemplate( data );
        return positions_template.getTemplate();
      });

      if ( positions && positions.length ) {
        this._positions_container.classList.add( 's-active' );
        this._positions_results_container.innerHTML = positions.join('');
      } else {
        this._positions_container.classList.remove( 's-active' );
        this._positions_results_container.innerHTML = '';
      }

      // @ts-ignore
      const misc: Array<string> = response_json.misc.map( ( data ) => {
        const misc_template = new MiscSearchResults( data );
        return misc_template.getTemplate();
      });

      if ( misc && misc.length ) {
        this._misc_results_container.innerHTML = misc.join('');  
      }

      window.dispatchEvent( this.enableLazyLoad );

    } else {

      console.error('We reached our target server, but it returned an error');

    }

  }


  private isValidSign( code: string ) {

    const valid_codes: Array<string> = [
      'Digit0','Digit1','Digit2','Digit3','Digit4','Digit5','Digit6','Digit7','Digit8','Digit9',
      'Numpad0','Numpad1','Numpad2','Numpad3','Numpad4','Numpad5','Numpad6','Numpad7','Numpad8','Numpad9',
      'NumpadEqual','NumpadDivide','NumpadMultiply','NumpadSubtract','NumpadAdd',
      'KeyA','KeyB','KeyC','KeyD','KeyE','KeyF','KeyG','KeyH','KeyI',
      'KeyJ','KeyK','KeyL','KeyM','KeyN','KeyO','KeyP','KeyQ','KeyR',
      'KeyS','KeyT','KeyU','KeyV','KeyX','KeyY','KeyZ',
      'Backquote','Backslash','BracketLeft','BracketRight',
      'Comma','Equal','Minus','Period','Quote','Semicolon','Slash'
    ];

    for ( let i: number = 0; i < valid_codes.length; i++ ) {
      
      if ( valid_codes[ i ] === code ) return true

    }

    return false;

  }

}