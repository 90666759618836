export default class Intro {

  private _intro_hidden: boolean = false;
  private _intro_element: HTMLElement = document.querySelector( '.c-intro' );
  

  constructor() {

    // const session_storage = window.sessionStorage;
    // const intro_shown: string = session_storage.getItem( 'STYXKEY_intro_shown' );

    if ( this._intro_element) {
    // if ( this._intro_element) {

      const text_elements: NodeListOf<HTMLElement> = this._intro_element.querySelectorAll( '.c-intro__word' );
      if ( text_elements ) {

        this._intro_element.classList.add( 's-ready' );
        this._intro_element.addEventListener( 'click', () => this.hideIntro( 0 ) );
        document.body.classList.add( 's-show-content' );

        for ( let i: number = 0; i < text_elements.length; i++ ) {
          
          setTimeout(() => {
            text_elements[i].classList.add( 's-visible' );
          }, i * 200 + 1800);
          
          if ( i < text_elements.length - 1 ) {
            setTimeout(() => {
              text_elements[i].classList.remove( 's-visible' );
            }, i * 200 + 3500);
          }
        }

        setTimeout(() => {
          this._intro_element.classList.add( 's-hide' );
        }, text_elements.length * 200 + 3300);

        setTimeout(() => this.hideIntro(), text_elements.length * 200 + 4500);
    
        // session_storage.setItem( 'STYXKEY_intro_shown', 'true' );

      }
      
    } else {

      // this.hideIntro();
      document.body.classList.add( 's-no-intro' );
      // document.body.classList.add( 's-show-content' );
      if ( this._intro_element ) this._intro_element.classList.add( 's-completed' );

    }

  }


  public showContent() {

    if ( !this._intro_hidden ) {

      document.body.classList.add('s-show-content');
      this._intro_hidden = true;

    }

  }
  

  private hideIntro( delay: number = 1) {
    
    // if ( !delay && delay !== 0 ) delay = this._text_length * .03;
    // if ( !delay && delay !== 0 ) delay = 2;
    // gsap.to( this._intro_element, { duration: .5, delay: delay, y: "-100%", onComplete: () => {
    this._intro_element.classList.add( 's-completed' );

  }

}