// import '../../css/teasers/person.css';

export default class ProjectTeaserTemplate {
  
  
  private _data: any;
  
  
  constructor( data: any ) {

    this._data = data;

  }


  public getTemplate() {

    let thumbnail = '';

    if ( this._data.thumbnail ) {

      thumbnail = `
        <div class="c-project-teaser__thumbnail-container">
          <div class="c-media">
            <picture>
              <source srcset="${this._data.thumbnail.srcset_webp}" type="image/webp">
              <source srcset="${this._data.thumbnail.srcset_jpg}" type="image/jpeg">
              <img
                sizes="${this._data.thumbnail.sizes}"
                src="${this._data.thumbnail.src}"
                alt="${this._data.thumbnail.alt}"
                class="c-media__image c-media__image--${this._data.thumbnail.direction} c-lazyload--image">
            </picture>
          </div>
        </div>
      `;
    }

    let summary = '';
    if ( this._data.summary && this._data.summary.length ) {
      summary = `
      <p class="c-project-teaser__summary">${this._data.summary}</p>
      `;
    }

    let categories = '';
    if ( this._data.categories && this._data.categories.length ) {
      const category_items = this._data.categories.map( item => {
        if ( item.parent === 0) return `<p class="c-tag c-tag--small">${item.name}</p>`
      }).join("");

      categories = `
        <div class="c-project-teaser__tags-container c-tag__list c-tag__list--grid">
          ${category_items}
        </div>
      `;
    }

    return `
      <a href="${this._data.permalink}" class="c-project-teaser c-project-teaser--small">
    
        ${thumbnail}
      
        <div class="c-project-teaser__content">
          <h3 class="c-project-teaser__title">${this._data.title}</h3>
          ${summary}
        </div>
      
      
        ${categories}
      
      </a>
    `;
  }

}