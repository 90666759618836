// import * as ES6Promise from "es6-promise";
// ES6Promise.polyfill();

import '../css/main.css';

import Dev from './global/Dev';
import Header from './global/Header';
import Intro from './global/Intro';
import { HistoryManager } from './andreasoby/controllers/HistoryManager';
import { PageTransition } from './andreasoby/controllers/PageTransition';
import LazyLoad from './andreasoby/controllers/Lazyload';
import Search from './global/Search';
// import Facts from './modules/Facts';
// import MediaModule from './modules/MediaModule';
// import NumberedList from './modules/NumberedList';
// import Person from './modules/Person';
// import Quote from './modules/Quote';
import Accordion from './global/Accordion';
import Cards from './global/Cards';
import Slideshow from './modules/Slideshow';
import TagCloud from './modules/TagCloud';
import Timeline from './modules/Timeline';
import TermsMenu from './global/TermsMenu';
// import Wysiwyg from './modules/Wysiwyg';
import Project from './pages/Project';
// import Post from './pages/Post';
// import PostsOverview from './pages/PostsOverview';
import ProjectsOverview from './pages/ProjectsOverview';
// import PersonTeaser from './teasers/PersonTeaser';
// import PostTeaser from './teasers/PostTeaser';
// import ProjectTeaser from './teasers/ProjectTeaser';
// import ProjectTeaserFull from './teasers/ProjectTeaserFull';

interface ModuleToLoad {
  selector: string,
  promise: any

}

// @ts-ignore
// window.loadArticle = async (): Promise<any> => {
//   return await import(/* webpackChunkName: "Article" */ './modules/Article');
// }
// // @ts-ignore
// window.loadSection = async (): Promise<any> => {
//   return await import(/* webpackChunkName: "Section" */ './modules/Section');
// }



class Main {

  
  private _history_manager: HistoryManager;
  private _page_transition: PageTransition;
  private _lazy_load: LazyLoad;
  private _lazy_load_intiated: boolean = false;

  private _header: Header;
  private _intro: Intro;

  constructor() {

    document.addEventListener('DOMContentLoaded', () => this.init());

  }


  private init() {

    if ( !document.body.classList.contains( 'wp-admin' ) ) {
     
      this._history_manager = new HistoryManager( [ '.c-main', '.c-overview__content' ], "", "" );
      this._page_transition = new PageTransition();

      this._lazy_load = new LazyLoad();
      
      this.setupListeners();
      this.setupContent();
      this.checkIfTouch();
    
      this._header = new Header();
      this._intro = new Intro();
      
      new Search();
      new Dev();

      // Add custom cursor if user isn't using a touch device
      if ( this.checkIfTouch() ) {
        
        document.body.classList.add( 'touch-device' );

        let vh = window.innerHeight;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        
      }
      
    }
    
  }


  private checkIfTouch() {

    try {

      document.createEvent("TouchEvent");
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      return true;

    } catch (e) {

      return false;

    }  

  }


  private setupListeners() {

    window.addEventListener( 'ajaxLinkClicked', ( event: CustomEvent ) => {

      this._page_transition.hideContent( event );

    } );

    window.addEventListener( 'ajaxSucceeded', ( event: Event ) => this.ajaxSucceeded( event ) );
    window.addEventListener( 'newContentInserted', () => this.setupContent() );
    window.addEventListener( 'contentUpdated', () => this.updateContent() );
    window.addEventListener( 'contentFullyRevealed', () => this.contentFullyRevealed() );

    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }

    window.addEventListener( 'popstate' , ( event: PopStateEvent ) => {
    
      event.preventDefault();
      this._page_transition.hideContent();
     
      const scroll_pos = history.state && history.state.scrollPos ? history.state.scrollPos : 0;
      this._page_transition.injectContent( scroll_pos );

      return false;

    } );

  }


  private ajaxSucceeded( event: Event ) {

    this._page_transition.injectContent();

  }


  private updateContent() {

    // this._header.updateLinks();
    this._history_manager.setupNewLinks();

  }


  private contentFullyRevealed() {

    

  }
  
  
  private setupContent() {
    
    // console.log('setupContent');
    // const modules: Array<ModuleToLoad> = [
    //   { selector: '.c-article', promise: 'loadArticle' },
    //   { selector: '.c-section', promise: 'loadSection' },
    // ];

    // const queue: Array<any> = [];
    // for ( let i: number = 0; i < modules.length; i++ ) {

    //   const element: HTMLElement = document.querySelector( modules[ i ].selector );
    //   //@ts-ignore
    //   if ( element ) queue.push( window[modules[ i ].promise]() );

    // }

    
    // Promise.all( queue ).then( ( modules: any ) => {
      
    //   for ( let i: number = 0; i < modules.length; i++ ) {
        
    //     // Get dynamic constructor name
    //     const name: string = Object.keys(modules[ i ] )[0];
    //     new modules[ i ][ name ];

    //   }

    
      // this._page_transition.showContent();
    
    // });

    // new Facts();
    // new MediaModule();
    // new NumberedList();
    // new Person();
    // new Quote();
    new Accordion();
    new Cards();
    new Slideshow();
    new TagCloud();
    new TermsMenu();
    new Timeline();
    // new Wysiwyg();

    // new PostsOverview();
    new ProjectsOverview();

    // new Post();
    new Project();

    // new PersonTeaser();
    // new PostTeaser();
    // new ProjectTeaser();
    // new ProjectTeaserFull();
    
    this._page_transition.showContent();

    if ( !this._lazy_load.intiated ) this._lazy_load.init();
    this._lazy_load.updateContent();

  }

}

new Main();