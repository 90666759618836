export default class PostTeaserTemplate {
  
  
  private _data: any;
  
  
  constructor( data: any ) {

    this._data = data;

  }


  public getTemplate() {

    let thumbnail_class = '';
    let thumbnail = '';

    if ( this._data.thumbnail ) {

      thumbnail_class = 'c-post-teaser--thumbnail';

      thumbnail = `
        <div class="c-post-teaser__thumbnail-container">
          <div class="c-media">
            <picture>
              <source srcset="${this._data.thumbnail.srcset_webp}" type="image/webp">
              <source srcset="${this._data.thumbnail.srcset_jpg}" type="image/jpeg">
              <img
                sizes="${this._data.thumbnail.sizes}"
                src="${this._data.thumbnail.src}"
                alt="${this._data.thumbnail.alt}"
                class="c-media__image c-media__image--${this._data.thumbnail.direction} c-lazyload--image">
            </picture>
          </div>
        </div>
      `;
    }

    let number = '';
    if ( this._data.number && this._data.number > -1 ) {
      number = `
        <div class="c-post-teaser__number-container">
          <p class="c-post-teaser__number">${this._data.number}</p>
        </div>
      `;
    }

    let summary = '';
    if ( this._data.summary && this._data.summary.length ) {
      summary = `
      <p class="c-post-teaser__summary">${this._data.summary}</p>
      `;
    }

    let categories = '';
    if ( this._data.categories && this._data.categories.length ) {
      const category_items = this._data.categories.map( item => {
        if ( item.parent === 0) return `<p class="c-tag c-tag--opacity c-tag--small">${item.name}</p>`;
      }).join("");

      categories = `
        <div class="c-post-teaser__tags-container c-tag__list c-tag__list--grid">
          ${category_items}
        </div>
      `;
    }

    return `
      <a href="${this._data.permalink}" class="c-post-teaser c-post-teaser--small ${thumbnail_class}">
    
        <div class="c-post-teaser__top">
          <div class="c-post-teaser__top__inner">
            
            ${thumbnail}

            <div class="c-post-teaser__top__content">
              
              <h3 class="c-post-teaser__title f-headline-small">${this._data.title}</h3>

              ${number}

            </div>
            
          </div>
        </div>

      <div class="c-post-teaser__content">
        ${summary}
      </div>


      ${categories}

    </a>
    `;
  }

}